import { render, staticRenderFns } from "./resources.vue?vue&type=template&id=737dae12&scoped=true"
import script from "./resources.vue?vue&type=script&lang=js"
export * from "./resources.vue?vue&type=script&lang=js"
import style0 from "./resources.vue?vue&type=style&index=0&id=737dae12&prod&scoped=true&lang=css"
import style1 from "./resources.vue?vue&type=style&index=1&id=737dae12&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "737dae12",
  null
  
)

export default component.exports